@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
/* 
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark: bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  } */
}

/* .glide__bullet--active{background-color: black;} */
@media (min-width: 768px) {
  .home-slider-img {
    max-height: 600px;
  }

  .event-detail-img {
    max-height: 600px;
    min-height: 300px;
  }
}
html:not(.dark) .select-input .MuiInputBase-root {
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
}

html:not(.dark) .select-input .MuiInputBase-root:hover {
  background-color: #f1f1f1 !important;
}
.grid li:marker{display:none!important}
.select-input .MuiInputBase-root:before,.select-input .MuiInputBase-root:after {
  display: none !important;
}
.btn-default .primary{
  background-color: #4f46e5!important;
}
.btn-default {
  font-size: 1rem!important;
  line-height: 1.5rem!important;
  padding-left: 1.5rem!important;
  padding-right: 1.5rem!important;
  color: rgba(55,65,81, 1)!important;
  font-weight: 500!important;
  padding-top: 0.75rem!important;
  padding-bottom: 0.75rem!important;
  background-color: rgb(255 255 255 / 1)!important;
  border-color: rgba(229,231,235,1)!important;
  border: 1.5px solid rgba(229,231,235,1)!important;
  border-radius: 9999px!important;
  font-family:'Poppins'!important;
}
.selector-progress{display: flex;justify-content: center;width: 100%;background-color: #ffffff90;position: absolute;top:0;left:0;z-index:999;height: 100%;align-items: center;border-radius: 16px;}
li.Mui-selected{background-color: #284d97d9!important;color:white!important}
li.Mui-selected:hover{background-color: #284d97d0!important;}
.max-h-200{max-height: 200px;}
@media (min-width:1200px){
  .nc-HomeSlider .glide__slide{min-height:calc(100vh - 220px)}
}
@media (max-width:767.77px){
  .m-w-sm{width: 100%;}
  .m-p-5{padding-left: 5px;padding-right: 5px;}
  .m-m-5{margin-left: 5px;margin-right: 5px;}
  .btn-default{font-size:12px!important}
}
html.dark .select-input:hover,html.dark .select-input:focus{background-color:#112233!important}
html.dark .select-input{background-color:#112233!important;}
html.dark .select-input .MuiSelect-filled, html.dark .select-input .MuiInputLabel-root{color:#fff!important}
html.dark .MuiInputBase-root{border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}